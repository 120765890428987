// vue-router.js

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
 mode: "history",
 scrollBehavior(to, from, savedPosition) {

  if (to.hash) {
    return {
      selector: to.hash,
      behavior: 'smooth',
    };
  } else if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
},
  routes: [
    {
      path: "/",
      alias: "/landing",
      name: "landing",
      component: () => import("./components/Landing.vue")
    },
    {
      path: "/shop",
      alias: "/shop",
      name: "shop",
      component: () => import("./components/ShopComponent.vue")
    },
    {
      path: "/aboutUs",
      alias: "/aboutUs",
      name: "aboutUs",
      component: () => import("./components/AboutUsComponent.vue")
    },
    {
      path: "/chart",
      alias: "/chart",
      name: "chart",
      component: () => import("./components/ChartComponent.vue")
    },
    
    {
      path: "/productDetails",
      alias: "/productDetails",
      name: "productDetails",
      component: () => import("./components/ProductDetails.vue")
    },
    {
      path: "/youMaylike",
      alias: "/youMaylike",
      name: "youMaylike",
      component: () => import("./components/includes/YouMaylike.vue")
    },
    {
      path: "/cart",
      alias: "/cart",
      name: "cart",
      component: () => import("./components/CartComponent.vue")
    },
    {
      path: "/addDocument",
      alias: "/addDocument",
      name: "addDocument",
      component: () => import("./components/AddDocument.vue")
    },
    {
      path: "/uploadEmiratesID",
      alias: "/uploadEmiratesID",
      name: "uploadEmiratesID",
      component: () => import("./components/UploadEmiratesID.vue")
    },
    {
      path: "/uploadPassport",
      alias: "/uploadPassport",
      name: "uploadPassport",
      component: () => import("./components/UploadPassport.vue")
    },
    {
      path: "/editPhone",
      alias: "/editPhone",
      name: "editPhone",
      meta: { requiresAuth: true },
      component: () => import("./components/includes/EditPhone.vue")
    },
    {
      path: "/checkOut",
      alias: "/checkOut",
      name: "checkOut",

      component: () => import("./components/CheckoutComponent.vue")
    },
    {
      path: "/productList",
      alias: "/productList",
      name: "productList",
      component: () => import("./components/includes/ProductList.vue")
    },
    {
      path: "/paymentGateway",
      alias: "/paymentGateway",
      name: "paymentGateway",
      component: () => import("./components/PaymentGateway.vue")
    },
    {
      path: "/paymentFailed",
      alias: "/paymentFailed",
      name: "paymentFailed",
      component: () => import("./components/PaymentFailed.vue")
    },
    {
      path: "/confirmedOrder",
      alias: "/confirmedOrder",
      name: "confirmedOrder",
      props: true,
      meta: { requiresAuth: true },
      component: () => import("./components/ConfirmedOrder.vue")
    },
    {
      path: "/login",
      alias: "/login",
      name: "login",
      component: () => import("./components/LoginComponent.vue")
    },
    {
      path: "/productSwiper",
      alias: "/productSwiper",
      name: "productSwiper",
      meta: { requiresAuth: true },
      component: () => import("./components/ProductSwiper.vue")
    },
    {
      path: "/userAccount",
      alias: "/userAccount",
      name: "userAccount",
      meta: { requiresAuth: true },
      component: () => import("./components/UserAccount.vue")
    },

    {
      path: "/addAddress",
      alias: "/addAddress",
      name: "addAddress",
      meta: { requiresAuth: true },
      component: () => import("./components/includes/AddAddress.vue")
    },
    {
      path: "/editAddress",
      alias: "/editAddress",
      name: "editAddress",
      props: true,
      meta: { requiresAuth: true },
      component: () => import("./components/includes/EditAddress.vue")
    },
    {
      path: "/guestAddress",
      alias: "/guestAddress",
      name: "guestAddress",
      props: true,

      component: () => import("./components/includes/GuestaddAddress.vue")
    },
    {
      path: "/userRegistration",
      alias: "/userRegistration",
      name: "userRegistration",
      component: () => import("./components/UserRegistration.vue")
    },
    {
      path: "/live-view",
      alias: "/live-view",
      name: "live-view",
      component: () => import("./components/LiveView.vue")
    },
    {
      path: "/resetPassword",
      alias: "/resetPassword",
      name: "resetPassword",
      component: () => import("./components/ResetPassword.vue")
    },
    {
      path: "/verifyOTP",
      alias: "/verifyOTP",
      name: "verifyOTP",
      props: true, 
      component: () => import("./components/VerifyOTP.vue")
    },
    {
      path: "/news_feed/:data",
      name: "news_feed",
      component: () => import("./components/BlogComponent.vue"),
      props: true,
    },
    {
      path: "/orderConfirmEmail",
      alias: "/orderConfirmEmail",
      name: "orderConfirmEmail",
      component: () => import("./components/email/OrderConfirmEmail.vue"),
      props: true,
    },
    {
      path: "/emailTemplate",
      alias: "/emailTemplate",
      name: "emailTemplate",
      component: () => import("./components/email/EmailTemplate.vue"),
      props: true,
    },
    {
      path: "/privacy-policy",
      name: "news_feed",
      component: () => import("./components/PrivacyPolicyComponent.vue")
    }
  ]
  
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('userData') /* Check user authentication status, e.g., from a store or localStorage */;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!isAuthenticated) {
      // If not authenticated, redirect to a login page or another route
      next('/login');
    } else {
      next(); // Proceed to the route
    }
  } else {
    next(); // For routes that don't require authentication
  }
});

export default router;
