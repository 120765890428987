import Vue from 'vue';
import App from './App.vue';
import router from './router';
import "./style.css";
import axios from 'axios';


import VueLoading from 'vue-loading-template'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';




Vue.use(VueLoading, /** options **/)
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.config.productionTip = false


// Get the access token from localStorage
const accessToken = localStorage.getItem('bearerToken');

// Check if an access token is available in localStorage
if (accessToken) {
  // Set the 'Authorization' header with the access token
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

localStorage.setItem('base_url', JSON.stringify('https://honor-fx-crm.s3.eu-west-2.amazonaws.com'));

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')